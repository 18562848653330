// ==============================|| CUSTOM SCROLLBAR ||============================== //
$scrollbarSize: 7px;
$scrollbarColor: rgba(#cdd5df, 70%);

// -webkit- browsers
::-webkit-scrollbar {
  width: $scrollbarSize;
  height: $scrollbarSize;
  background: transparent;

  &-thumb {
    background: $scrollbarColor;
  }
}

// Firefox
* {
  scrollbar-width: thin;
  scrollbar-color: $scrollbarColor transparent;
}
